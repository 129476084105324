const style = `
  align-content: center;
  align-items: center;
  list-style: none;
  padding: 0px;
  text-align: center;
  margin: 0px;
`

export default style
