const style = `
  border-width: 4px;
  border-style: solid;
  align-content: center;
  justify-content: center;
  overflow-wrap: break-word;
  display: flex;
`

export default style
